import React from "react";
import { NavLink } from "react-router-dom";
import { Config, schemaMarkup } from "../../../config";
import PaymentfailedImg from "../../../assets/img/failed-payment.avif";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Common/Navbar";

const PaymentFailed = () => {
  return (
    <div className="notfound bg-white">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://www.jeantherapyonline.co.uk" />
      </Helmet>
      <div>
        <div className="notfound-img ">
          {/* <NavLink to={`${Config.appUrl}`}>
            <img src={Logo} alt="Jeantherapyonline Logo"></img>
          </NavLink> */}
          <Navbar />
        </div>
        <div className="notfound-content pt-0">
          <div className="notfound-flex align-items-center">
            <div className="content-div col-md-6">
              <h2 className="payment-success">Oops! Payment failed</h2>
              <p>
                We couldn't process your transaction at this time.Please review
                your payment details or reach out to our support for help.
              </p>
              <div className="home-button">
                <NavLink to={`${Config.appUrl}`}>Go to Homepage</NavLink>
              </div>
            </div>
            <div className="notfound_img ">
              <img
                loading="lazy"
                src={PaymentfailedImg}
                alt="Jeantherapyonline Logo"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
