import React from "react";
import Logo from "../../assets/img/logo-back.png";
import { NavLink } from "react-router-dom";
import { Config, schemaMarkup } from "../../config";
import notfound from "../../assets/img/notfound2.webp";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <div className="notfound">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href='https://www.jeantherapyonline.co.uk' />
      </Helmet>
      <div>
        <div className="notfound-img">
          <NavLink to={`${Config.appUrl}`}>
            <img src={Logo} alt="Jeantherapyonline Logo"></img>
          </NavLink>
        </div>
        <div className="notfound-content">
          <div className="notfound-flex">
            <div className="content-div">
              <h2>So Sorry!</h2>
              <h3>The page you are looking for cannot be found</h3>
              <div>
                <b>Possible Reasons</b>
                <ul>
                  <li>The address may have been typed incorrectly.</li>
                  <li>It may be a broken or outdated link.</li>
                </ul>
              </div>
              <div className="home-button">
                <NavLink to={`${Config.appUrl}`}>Go to Homepage</NavLink>
              </div>
            </div>
            <div className="notfound_img">
              <img loading="lazy" src={notfound} alt="Jeantherapyonline Logo"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
