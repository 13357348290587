import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "../Footer";
import Loader from "./Loader";
import { useParams } from "react-router";
import axios from "axios";
import { Config, getDecryptedData, schemaMarkup } from "../../../config";
import toast, { Toaster } from "react-hot-toast";
import NotFoundPage from "../NotFoundPage";
import { Helmet } from "react-helmet";

const TherapistDetail = () => {
  const { slug } = useParams();
  const [therapist, setTherapist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const decodeSlug = (slug) => {
    return slug.replace(/-/g, " ");
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted
    const fetchTherapistData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Config.apiUrl}/therapistList`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const therapists = response.data.data;
        const decodedName = decodeSlug(slug);
        const foundTherapist = therapists.find(
          (therapist) =>
            getDecryptedData(therapist.name)?.replace(/-/g, " ").toLowerCase() ==
            decodedName.toLowerCase()
        );
        //console.log("foundTherapist", foundTherapist);
        if (isMounted) {
          if (foundTherapist) {
            setTherapist({
              name: getDecryptedData(foundTherapist.name),
              description: getDecryptedData(foundTherapist.description),
              image: foundTherapist.web_img,
            });
            setNotFound(false);
          } else {
            setNotFound(true);
          }
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          if (error.response && error.response.status === 429) {
            toast.error("Too many requests. Please try again later.");
          } else {
            toast.error("Something went wrong while fetching the data!");
          }
          setLoading(false);
        }
        console.error("There was a problem fetching the data:", error);
      }
    };

    fetchTherapistData();

    return () => {
      isMounted = false;
    };
  }, [slug]);

  if (notFound) {
    return <NotFoundPage />;
  }

  return (
    <div className="overflow">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href={`https://www.jeantherapyonline.co.uk/therapistdetail/${slug}`} />
      </Helmet>
      <Toaster />
      {loading && <Loader />}
      <div className="page-container">
        <Navbar />

        <div className="therapist-det p-5">
          <div>Therapist Details</div>
        </div>

        <div style={{ overflow: "hidden" }}>
          <div>
            {therapist ? (
              <div className="details-section">
                <div className="bg-white">
                  <div className="details-banner">
                    <div className="details-banner1">
                      <div className="therapist-img">
                        <img src={therapist.image} alt={therapist.name} />
                      </div>
                      <div className="therapistdet_text">
                        <span>{therapist.name}</span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: therapist.description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="details-section">
                <div className="bg-white">
                  <div className="details-banner">
                    <div className="details-banner1">
                      <div>Details Not Found !!</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TherapistDetail;
