import React from "react";

const SvgDivider = () => {
    return (
        <div className="">
            <div style={{ height: '150px', overflow: 'hidden' }} >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path d="M-0.85,110.03 C195.54,2.47 325.34,196.88 500.85,88.32 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#e4f4f3' }}>
                    </path>
                </svg>
            </div>
        </div>
    )
}
export default SvgDivider