import React, { useEffect, useState } from "react";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Config, getDecryptedData, getEncryptedData, schemaMarkup } from "../../config";
import Loader from "../components/Common/Loader";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const [showSection, setShowSection] = useState(false);

  const customToastOptions = {
    style: {
      border: "1px transparent #ccc",
      padding: "30px 50px",
      backgroundColor: "#c19356",
      color: "white",
      borderRadius: "10px",
      fontSize: "1.3rem",
      whiteSpace: "nowrap",
      minWidth: "450px",
      fontWeight: "300",
    },
  };

  const pageName = "contactus";
  const [radiobtn, setRadioBtn] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");

  const [isLoading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 300,
    });
  });

  const handleValidation = () => {
    const newErrors = {};

    if (!firstname) {
      newErrors.firstName = "First Name is required";
    }

    if (!lastname) {
      newErrors.lastName = "Last Name is required";
    }

    if (!message) {
      newErrors.message = "Message is required";
    }

    if (!emailAddress) {
      newErrors.email = "Email is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)
    ) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const data = new FormData();

      data.append("fname", firstname);
      data.append("lname", lastname);
      data.append("email", emailAddress);
      data.append("description", message);

      setLoading(true);

      axios
        .post(`${Config.apiUrl}/contactUs`, data, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.success === true) {
            toast.success(<div>Message Sent Successfully!</div>, {
              duration: 5000,
            });
            setFirstName("");
            setLastName("");
            setEmailAddress("");
            setMessage("");
          } else {
            toast.error(
              <div>
                Something Went Wrong!
                <br />
                Please try again.
              </div>
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
          console.error("There was a problem fetching the data:", error);
          setLoading(false);
        });
    }
  };

  return (
    <div>
     <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://www.jeantherapyonline.co.uk/contactus" />
      </Helmet>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={customToastOptions}
      />
      {isLoading && <Loader />}
      <div className="page-container">
        <Navbar pageName={pageName} />
        <div className="contact-text">
          <div className="contact-main1">
            <div className="contact-container" data-aos="fade-in">
              <h1>Contact Us</h1>
              <p>
                Use this form to reach out to our Customer Service Team
                regarding any questions, concerns, or feedback. You can also
                view our{" "}
                <NavLink
                  style={{ textDecoration: "none", color: "#00b6bc" }}
                  to={`${Config.appUrl}faq`}
                >
                  FAQ
                </NavLink>{" "}
                for quick answers to commonly asked questions.
              </p>
            </div>
          </div>
        </div>
        <div className="" id="form">
          <section className="support-content">
            <div className="bg-white">
              <div className="container" data-aos="fade-in">
                <div className="form-top">
                  <h2>Take care of your mental health </h2>
                  <form>
                    <div className="form-top">
                      <div className="col-sm-12 name-col">
                        <p>
                          <label>First Name</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={firstname}
                              type="text"
                              placeholder="Enter First Name"
                              onChange={(e) => {
                                setFirstName(e.target.value);
                              }}
                            ></input>
                            {errors.firstName && (
                              <span className="text-danger">
                                {errors.firstName}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <p>
                          <label>Last Name</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={lastname}
                              type="text"
                              placeholder="Enter Last Name"
                              onChange={(e) => {
                                setLastName(e.target.value);
                              }}
                            ></input>
                            {errors.lastName && (
                              <span className="text-danger">
                                {errors.lastName}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <p>
                          <label>Email Address</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={emailAddress}
                              type="text"
                              placeholder="Enter Email Address"
                              onChange={(e) => {
                                setEmailAddress(e.target.value.toLowerCase());
                              }}
                            ></input>
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <p>
                          <label>Message</label>
                          <br />
                          <span>
                            <textarea
                              className="form-control"
                              value={message}
                              rows="4"
                              cols="40"
                              placeholder="Leave us Message"
                              onChange={(e) => {
                                setMessage(e.target.value);
                              }}
                            ></textarea>
                            {errors.message && (
                              <span className="text-danger">
                                {errors.message}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-6 button">
                        <p>
                          <input
                            className="form-control"
                            onClick={handleSubmit}
                            type="submit"
                            value="Submit"
                            style={{
                              backgroundColor: "#00b6bc",
                              color: "white",
                            }}
                          ></input>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="contact-form_address">
                  <div className="well">
                    <div className="contactaddress_header">
                      <span>Contact Details</span>
                    </div>
                    {/* <div className="addr">
                                            <i className="bi bi-geo-alt"></i>
                                            <div>
                                                <span>72 Scarcroft Road</span><br />
                                                <span>United Kingdom L62 0EB</span>
                                            </div>
                                        </div> */}

                    <div className="phone-info">
                      <div>
                        <i className="bi bi-telephone"></i>
                      </div>
                      <div>
                        <a className="text-none" href="tel:+44 (0)1254 241186">
                          +44 (0)1254 241186
                        </a>{" "}
                        <br />
                        <a className="text-none" href="tel:+44 (0)7512788885">
                          +44 (0)7512788885
                        </a>
                      </div>
                    </div>

                    <div className="email-info">
                      <div>
                        <i className="bi bi-envelope"></i>
                      </div>
                      <div>
                        <a
                          className="text-none"
                          href="mailto:info@jeantherapyonline.co.uk"
                        >
                          info@jeantherapyonline.co.uk
                        </a>
                      </div>
                    </div>

                    <div className="follow-us">
                      <div>Follow us on</div>
                      <div>
                        <div className="social-logo1">
                          <div>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/ipsumcounsellingandcoaching/"
                            >
                              <i className="bi bi-facebook"></i>
                            </a>
                          </div>
                          <div>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/jeantherapyonline/?hl=en"
                            >
                              <i className="bi bi-instagram"></i>
                            </a>
                          </div>
                          <div>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/in/jean-kimberley-langford-ncps-accred-diphe-dso-mhfa-690853131/"
                            >
                              <i className="bi bi-linkedin"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="image-container">
                                        <img src={img} alt="Image" />
                                    </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default ContactUs;
