import React from "react";
import ctaimg from "../../../assets/img/positive-young-hindu-lady-freelancer-working-on-la-2023-11-27-04-55-54-utc-scaled.webp"
import { Config } from "../../../config";
import { NavLink } from "react-router-dom";

const TransformCTA = () => {

    return (
        <>
            <div className='section-content'>
                <div className='cta' style={{ marginBottom: '5%' }}>
                    <div className='cta_img' data-aos="fade-in">
                        <img src={ctaimg} alt='call to action'></img>
                    </div>
                    <div className='cta_text'>
                        <div className='cta-content'>
                            <div className='heading'>
                                Transform Your Life:
                            </div>
                            <div className='main-text'>
                                <h4>
                                    Dive into Our Online Counselling on Mental Health, Relationships, and Emotional Well-being. Start Your Path to Healing and Growth.
                                </h4>
                            </div>
                            <div className="elementor-element" >
                                <div className="elementor-button1">
                                    <div className="elementor-button-wrapper">
                                        <a className="elementor-button elementor-size-sm " style={{ backgroundColor: '#c19356' }}>
                                            <span className="">
                                                <NavLink className="" style={{ textDecoration: 'none', color: 'black' }} to={`${Config.appUrl}contactus#form`}>Contact Us</NavLink>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default TransformCTA