import React, { useEffect, useState } from "react";
import Navbar from "./Common/Navbar";
import Footer from "./Footer";
import "aos/dist/aos.css";
import AOS from "aos";
import { Config, schemaMarkup } from "../../config";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import Loader from "./Common/Loader";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";

const BlogDetails = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const { slug } = useParams();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 200,
    });
  });

  //const selectedBlog = blogPosts[slug];
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);
  const [allblogs, setAllBlogs] = useState([]);

  const createSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-+|-+$/g, "");
  };

  const decodeSlug = (slug) => {
    return slug.replace(/-/g, " ");
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const dateDifferenceInDays = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const currentDate = new Date();

  useEffect(() => {
    let isMounted = true;
    const fetchTherapistData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Config.apiUrl}/blogs`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const blogs = response.data.data;
        //const decodedName = createSlug(slug);
        const blog1 = blogs.find((blog) => createSlug(blog.title) === slug);
        //console.log("foundTherapist", blog1);
        if (isMounted) {
          if (blog1) {
            setBlogData({
              title: blog1.title,
              content: blog1.content,
              image: blog1.image_url,
            });
          }
          const allBlogs = blogs.map((item) => ({
            title: item.title,
            image: item.image_url,
            date: formatDate(item.created_at),
            link: createSlug(item.title),
          }));

          const sortedData = allBlogs.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return (
              dateDifferenceInDays(dateA, currentDate) -
              dateDifferenceInDays(dateB, currentDate)
            );
          });

          const nearestThreeBlogs = sortedData.slice(0, 3);

          //console.log(nearestThreeBlogs);

          //setBlogData(nearestThreeBlogs);
          //console.log("allBlogs", allBlogs);
          setAllBlogs(nearestThreeBlogs);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          if (error.response && error.response.status === 429) {
            toast.error("Too many requests. Please try again later.");
          } else {
            toast.error("Something went wrong while fetching the data!");
          }
          setLoading(false);
        }
        console.error("There was a problem fetching the data:", error);
      }
    };

    fetchTherapistData();

    return () => {
      isMounted = false;
    };
  }, [slug]);

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href={`https://www.jeantherapyonline.co.uk/blogdetail/${slug}`} />
      </Helmet>
      {loading && <Loader />}
      <div className="page-container">
        <Navbar />
        <div>
          <div className="blog-detail-title p-5" data-aos="fade-up">
            Blog Details
          </div>
          <div className="blog-detail">
            <div className="bg-white">
              {/* <div className="p-4">
                                <div className="blogdetail-title" data-aos="fade-in">
                                    <h2>{selectedBlog.title}</h2>
                                </div>
                            </div> */}

              <div className="blog-flex">
                {blogData && (
                  <div className="blogdetail-main">
                    <div className="blogdetail-img" data-aos="fade-in">
                      <img loading="lazy" src={blogData.image} alt="Blog Image"></img>
                    </div>

                    <div className="blogdetail-title" data-aos="fade-in">
                      <h2>{blogData.title}</h2>
                    </div>

                    <div
                      dangerouslySetInnerHTML={{ __html: blogData.content }}
                    />
                  </div>
                )}
                <div className="recent-blog1">
                  <div className="recent-blog-div" data-aos="fade-in">
                    <h3>Recent Blog Posts</h3>
                    <div>
                      {allblogs.map((blog, index) => (
                        <div
                          key={index}
                          className="blog-list"
                          data-aos="fade-in"
                        >
                          <NavLink
                            to={`${Config.appUrl}blogdetail/${blog.link}`}
                          >
                            <img
                              loading="lazy"
                              src={blog.image}
                              alt={blog.title}
                            />
                          </NavLink>
                          <div className="ms-3 mrgin-none">
                            <div className="grey-text">
                              <NavLink
                                to={`${Config.appUrl}blogdetail/${blog.link}`}
                              >
                                {blog.title}
                              </NavLink>
                            </div>
                            <p>{blog.date}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default BlogDetails;
