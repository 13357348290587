import React from 'react'
import footer_img from '../../assets/img/footer_background.jpg'
import logo from '../../assets/img/logo.jpg'
import { Config } from '../../config'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {

    const handleLinkClick = (event) => {
        event.preventDefault();
        const href = event.target.getAttribute('href');
        window.location.href = href;
    };

    return (
        <div>

            <footer style={{ backgroundImage: `url(${footer_img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className='row_wrapper'>
                    <div className='row_wrapper1'>
                        <div className='div1'>
                            <div>
                                <div>
                                    <div className='footer_logo'>
                                        <span>
                                            <Link to={`${Config.appUrl}`}><img src={logo} alt="Jeantherapyonline Logo"></img></Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='logo-text'>
                                    <p>
                                        Center for Counselling & Psychotherapy
                                    </p>
                                </div>

                                <div className='social-logo'>
                                    <div>
                                        <a target='_blank' href='https://www.facebook.com/ipsumcounsellingandcoaching/' style={{ textDecoration: 'none' }} aria-label="Facebook Link">
                                            <i className='bi bi-facebook'></i></a>
                                    </div>
                                    <div>
                                        <a target='_blank' href='https://www.instagram.com/jeantherapyonline/?hl=en' aria-label="Instagram Link" style={{ textDecoration: 'none' }}>
                                            <i className='bi bi-instagram'></i>
                                        </a>
                                    </div>
                                    <div>
                                        <a target='_blank' href='https://www.linkedin.com/in/jean-kimberley-langford-ncps-accred-diphe-dso-mhfa-690853131/' style={{ textDecoration: 'none' }} aria-label="Linkedin Link">
                                            <i className='bi bi-linkedin'></i>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='div2'>
                            <div style={{ width: '100%' }}>
                                <div>
                                    <div className='inner-div'>
                                        <NavLink to={`${Config.appUrl}privacy-policy`}>
                                            <span>
                                                Privacy Policy
                                            </span>
                                            {/* <i className='bi bi-arrow-right'></i> */}
                                        </NavLink>
                                    </div>
                                </div>
                                <div>
                                    <div className='inner-div'>
                                        <NavLink to={`${Config.appUrl}terms-and-condition`}>
                                            <span>
                                                Terms and Condition
                                            </span>
                                            {/* <i className='bi bi-arrow-right'></i> */}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className='seperator'></div>

                            <div className="bt_bb_row_wrapper">
                                <div className="bt_bb_row_inner">
                                    <div className="bt_bb_column_inner col-xxl-4 col-xl-4 col-xs-12 col-sm-12 col-md-12 col-lg-4 bt_bb_vertical_align_top bt_bb_align_left bt_bb_padding_normal">
                                        <div className="bt_bb_column_inner_content">
                                            <header className="bt_bb_headline bt_bb_dash_none bt_bb_superheadline bt_bb_superheadline_outside "
                                            >
                                                <div className="bt_bb_headline_superheadline_outside">
                                                    <span className="bt_bb_headline_superheadline1">QUICK LINKS</span>
                                                </div>
                                            </header>
                                            <div className="bt_bb_separator_v2 "
                                            >
                                                <div className="bt_bb_separator_v2_inner">
                                                    <span className="bt_bb_separator_v2_inner_before"></span>
                                                    <span className="bt_bb_separator_v2_inner_content">
                                                        <span data-ico-="" className="bt_bb_icon_holder">
                                                        </span>
                                                    </span>
                                                    <span className="bt_bb_separator_v2_inner_after">
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="bt_bb_custom_menu"
                                            >
                                                <div className="menu-custom-menu-container">
                                                    <ul id="menu-custom-menu" className="menu">
                                                        <li id="" className="menu-item "><NavLink to={`${Config.appUrl}`} aria-current="page" >Home</NavLink></li>
                                                        <li className="menu-item"><NavLink to={`${Config.appUrl}get-counselling`}>Get Counselling</NavLink></li>
                                                        <li className="menu-item"><NavLink to={`${Config.appUrl}why-join-the-therapy-team`}>Become a Therapist</NavLink></li>
                                                        <li className="menu-item"><NavLink to={`${Config.appUrl}blog`}>Blog</NavLink></li>
                                                        <li className="menu-item"><NavLink to={`${Config.appUrl}aboutus`}>About Us</NavLink></li>
                                                        <li className="menu-item"><NavLink to={`${Config.appUrl}contactus`}>Contact Us</NavLink></li>
                                                        <li className="menu-item"><NavLink to={`${Config.appUrl}faq`}>FAQs</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="bt_bb_separator_v2">
                                                <div className="bt_bb_separator_v2_inner">
                                                    <span className="bt_bb_separator_v2_inner_before">
                                                    </span><span className="bt_bb_separator_v2_inner_content">
                                                        <span className="bt_bb_icon_holder">
                                                        </span>
                                                    </span>
                                                    <span className="bt_bb_separator_v2_inner_after">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="bt_bb_column_inner col-xxl-4 col-xl-4 col-xs-12 col-sm-12 col-md-12 col-lg-4 bt_bb_vertical_align_top bt_bb_align_left bt_bb_padding_normal">
                                        <div className="bt_bb_column_inner_content">
                                            <header className="bt_bb_headline bt_bb_superheadline bt_bb_superheadline_outside btNoHeadline bt_bb_size_small bt_bb_align_inherit" >
                                                <div className="bt_bb_headline_superheadline_outside">
                                                    <span className="bt_bb_headline_superheadline1">LOCATION</span>
                                                </div>
                                            </header>
                                            <div className="bt_bb_separator_v2">
                                                <div className="bt_bb_separator_v2_inner">
                                                    <span className="bt_bb_separator_v2_inner_before"></span>
                                                    <span className="bt_bb_separator_v2_inner_content">
                                                        <span className="bt_bb_icon_holder"></span>
                                                    </span>
                                                    <span className="bt_bb_separator_v2_inner_after"></span>
                                                </div>
                                            </div>
                                            <div className="bt_bb_text2" >
                                                <p>72 Scarcroft Road<br />
                                                    United Kingdom L62 0EB</p>
                                            </div>
                                            <div className="bt_bb_separator_v2 bt_bb_border_style_none">
                                                <div className="bt_bb_separator_v2_inner">
                                                    <span className="bt_bb_separator_v2_inner_before"></span>
                                                    <span className="bt_bb_separator_v2_inner_content">
                                                        <span className="bt_bb_icon_holder">
                                                        </span>
                                                    </span>
                                                    <span className="bt_bb_separator_v2_inner_after"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="bt_bb_column_inner col-xxl-4 col-xl-4 col-xs-12 col-sm-12 col-md-12 col-lg-4 bt_bb_vertical_align_top bt_bb_align_left bt_bb_padding_normal"><div className="bt_bb_column_inner_content"><header className="bt_bb_headline bt_bb_dash_none bt_bb_superheadline bt_bb_superheadline_outside btNoHeadline bt_bb_size_small bt_bb_align_inherit" >
                                        <div className="bt_bb_headline_superheadline_outside">
                                            <span className="bt_bb_headline_superheadline1">CONTACT</span>
                                        </div>
                                    </header>
                                        <div className="bt_bb_separator_v2">
                                            <div className="bt_bb_separator_v2_inner">
                                                <span className="bt_bb_separator_v2_inner_before"></span>
                                                <span className="bt_bb_separator_v2_inner_content">
                                                    <span data-ico-="" className="bt_bb_icon_holder"></span>
                                                </span>
                                                <span className="bt_bb_separator_v2_inner_after"></span>
                                            </div>
                                        </div>
                                        <div className="bt_bb_text2" ><p>
                                            <a href='tel:+44 (0)1254 241186'><i className='bi bi-telephone'></i> : +44 (0)1254 241186 </a> /
                                            <a href='tel:+44 (0)7512788885'> +44 (0)7512788885</a> <br />
                                            {/* <a style={{ textDecoration: 'none', color: 'white' , marginLeft:'20px'}} href='tel:+44 07512788885'> : 07512788885  </a><br/> */}
                                            <a href='mailto:info@jeantherapyonline.co.uk'><i className='bi bi-envelope'></i> :  info@jeantherapyonline.co.uk</a></p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bottom-footer bg-dark container-box py-4 px-5 d-flex flex-md-row flex-column 
                            justify-content-between text-white paragraph text-md-start text-center'>
                    <div>
                        <span className='pb-md-0 pb-2'>&copy; 2024 Jean Therapy Online. </span>
                    </div>
                    <a href='https://www.zithas.co.uk/' target='_blank' rel="noreferrer" className='text-white text-decoration-none'>Designed By Zithas Technologies</a>
                </div>
            </footer>
        </div>
    )
}
export default Footer