import { CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Loader from './Common/Loader';
import { useNavigate } from 'react-router-dom';
import { Config, getDecryptedData } from '../../config';
import toast, { Toaster } from 'react-hot-toast';

const CheckoutForm = ({ MainTotal, data, onClosePaymentModal }) => {


    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    

    const [success, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const customToastOptions = {
        style: {
            border: '1px transparent #ccc',
            padding: '27px 60px',
            backgroundColor: '#c19356',
            color: 'white',
            borderRadius: '10px',
            fontSize: '1.2rem',
            whiteSpace: 'nowrap',
            minWidth: '400px',
            fontWeight: '510',
            maxWidth: '80%',
            zIndex: 9999,
        },
    };


    const handlePayment = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        })

        if (!error) {
            try {
                const { id } = paymentMethod

                const personalResponse = await axios.post(`${Config.apiUrl}/addPersonal`, {
                    name: data.name,
                    last_name: data.last_name,
                    emergency_contact: data.emergency_contact,
                    emergency_contact_name: data.emergency_contact_name,
                    phone: data.phone,
                    email: data.email,
                    birthdate: data.birthdate,
                    gender: data.gender,
                    type: 3
                });

                if (personalResponse.data.success) {

                    const response = await axios.post(`${Config.apiUrl}/createPayment`, {
                        amount: parseFloat(MainTotal).toFixed(2),
                        total_amount: MainTotal,
                        method: paymentMethod?.id,
                        booking_type: data.booking_type,
                        price: data.price,
                        count: data.count,
                        user_id: personalResponse.data.data.id
                    });

                    //console.log("addPersonal api Response",personalResponse);

                    if (response.status === 200) {
                        const userId = personalResponse.data.data.id;
                        const medicalResponse = await axios.post(`${Config.apiUrl}/addMedical`, {
                            user_id: personalResponse.data.data.id,
                            previous_services: data.previous_services,
                            current_health_issue: data.current_health_issue,
                            medical_history: data.medical_history,
                            therapy_type: data.therapy_type,
                            primary_reason: data.primaryreason,
                            impact: data.impact,
                            mental_health_history: data.diagonised,
                            condition: data.ifDiagonised,
                            seeing_professionals: data.currentlyseeing,
                            suicide_thoughts: data.suicide,
                            planned_to_act: data.ifSuicide,
                            harming_self: data.harmingyourself,
                            acted_lastweek: data.ifHarmingyourself,
                            psychosis: data.experiencedpsychosis,
                            last_12_months: data.ifExperiencedpsychosis,
                            name_string: getDecryptedData(data.name),
                            attempt: (getDecryptedData(data.ifSuicide) === 'yes' ||
                                getDecryptedData(data.ifHarmingyourself) === 'yes') ? 1 : 0
                        });
                        //console.log("addMedical api Response",medicalResponse);

                        if (medicalResponse.data.success) {
                            const paymentResponse = await axios.post(`${Config.apiUrl}/addPayment`, {
                                booking_type: data.booking_type,
                                payment_type: data.payment_type,
                                payment_id: response.data.clientSecret?.id,
                                payment_status: 'unpaid',
                                package_id: data.package_id,
                                price: data.price,
                                count: data.count,
                                user_id: personalResponse.data.data.id,
                                total_amount: MainTotal
                            });
                            //console.log("addPayment api Response", paymentResponse);

                            if (paymentResponse.data.success) {
                                toast.success(
                                    <div id="jeanonlinecustomerform">
                                        Registered Successfully!
                                        <br />
                                        Please check your mail.
                                    </div>,
                                    { duration: 12000 }
                                );
                                onClosePaymentModal()
                                setTimeout(() => {
                                    setLoading(false);
                                    window.location.reload();
                                }, 12000);
                            } else {
                                toast.error('Something Went Wrong!');
                            }
                        } else {
                            toast.error('Something Went Wrong!');
                        }
                    }
                    else {
                        toast.error(
                            <div>
                                Something Went Wrong!
                                <br />
                                Payment Not Successful.
                            </div>,
                            { autoClose: 8000 }
                        );
                    }
                }
                else if (personalResponse.data.data === 'email already taken') {
                    toast.error('This email is already taken!', {
                        iconTheme: {
                            primary: '#c19356',
                            secondary: '#c19356',
                        },
                    });
                }
                else {
                    toast.error('Something Went Wrong!');
                    // toast.error(
                    //     <div>
                    //         Something Went Wrong!
                    //         <br />
                    //         Payment Not Successful.
                    //     </div>,
                    //     { autoClose: 8000 }
                    // );
                }

                setLoading(false)
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
            finally {
                setLoading(false);
            }
        } else {
            console.log(error);
            setLoading(false);
        }
    }

    const cardElementOptions = {
        style: {
            base: {
                fontSize: '20px',
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
            },
        },
        hidePostalCode: true
    };

    return (
        <div>
            {/* <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} /> */}
            {isLoading && (<Loader />)}
            {!success ? (

                <form onSubmit={handlePayment}>
                    <label className='card-label'>Card Details</label>
                    <CardElement className='card-element' options={cardElementOptions} />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button className='pay-button text-center' type="submit">
                            Pay Now -<span style={{ fontWeight: "650" }}>&nbsp;£{MainTotal}</span>
                        </button>
                    </div>
                </form>
            ) : (
                <div>
                    <p>Payment Done Successfully</p>
                </div>
            )}

        </div>
    )
}

export default CheckoutForm