import React, { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import swal from 'sweetalert';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Config, getDecryptedData, getEncryptedData } from '../../../config';
import Loader from './Loader';

const ContactSection = () => {
  useEffect(() => {

    AOS.init({
      duration: 1000,
      once: false,
    });
  });

  const customToastOptions = {
    style: {
      border: '1px transparent #ccc',
      padding: '30px 50px',
      backgroundColor: '#c19356',
      color: 'white',
      borderRadius: '10px',
      fontSize: '1.3rem',
      whiteSpace: 'nowrap',
      minWidth: '450px',
      fontWeight: '300'
    },
  };

  const [name, setFirstName] = useState('');
  const [email, setEmailAddress] = useState('');
  const [date, setDate] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [isLoading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleValidation = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'Name is required';
    }

    if (!message) {
      newErrors.message = 'Message is required';
    }

    if (!phone) {
      newErrors.phone = 'Phone Number is required';
    } else if (!/^[+\d]{7,15}$/.test(phone)) {
      newErrors.phone = 'Invalid Phone Number';
    }

    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = 'Invalid email format';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {

      setLoading(true);

      const data = new FormData();

      data.append("fname", name);
      data.append("email", email);
      data.append("phone", phone);
      data.append("description", message);

      axios.post(`${Config.apiUrl}/contactUs`, data,
        {
          headers: {
            //'Authorization': 'Bearer ',
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          const data = response.data;
          if (data.success === true) {
            toast.success(
              <div>
                Message Sent Successfully!
              </div>,
              { autoClose: 8000 }
            );
            setFirstName("");
            setEmailAddress("");
            setPhone("");
            setMessage("");
          }
          else {
            toast.error('Something Went Wrong!');
          }
          setLoading(false);
        })
        .catch(error => {
          toast.error('Something Went Wrong!');
          console.error('There was a problem fetching the data:', error);
          setLoading(false);
        });
    }
  }

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />
      {isLoading && <Loader />}
      <div className='contact-section' data-aos="fade-up">
        <div className='contact-main'>
          <div>
            <div className='h1content'>
              <h1>GET IN TOUCH WITH US</h1>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <p>
                  <label>Name</label><br />
                  <span>
                    <input className='form-control' value={name} type='text'
                      onKeyPress={(e) => {
                        if (/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFirstName(e.target.value)
                      }}
                      placeholder='Enter Name'></input>
                    {
                      errors.name && <span className='text-danger'>{errors.name}</span>
                    }
                  </span>
                </p>
              </div>
              <div className='col-sm-6' >
                <p>
                  <label>Email</label><br />
                  <span>
                    <input className='form-control' value={email}
                      onChange={(e) => {
                        setEmailAddress(e.target.value.toLowerCase())
                      }}
                      type='text' placeholder='Enter Email'></input>
                    {
                      errors.email && <span className='text-danger'>{errors.email}</span>
                    }
                  </span>
                </p>
              </div>

              <div className='col-sm-6'>
                <p>
                  <label>Phone</label><br />
                  <span>
                    <input className='form-control' value={phone}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                        setPhone(sanitizedValue);
                      }}
                      type='text' placeholder='Phone Number'></input>
                    {
                      errors.phone && <span className='text-danger'>{errors.phone}</span>
                    }
                  </span>
                </p>
              </div>
              <div className='col-sm-12'>
                <p>
                  <label>Message</label><br />
                  <span>
                    <textarea className='form-control' value={message}
                      onChange={(e) => {
                        setMessage(e.target.value)
                      }}
                      rows="4" cols="40" placeholder='Leave us Message'></textarea>
                    {
                      errors.message && <span className='text-danger'>{errors.message}</span>
                    }
                  </span>
                </p>
              </div>
              <div className='padding-seperator'></div>
              <div className='col-sm-12 button-div'>
                <p>
                  <input className='form-control' onClick={handleSubmit} type='submit' value='Send Your Request'></input>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContactSection