import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/img/logo-back.png";

const SecureLogon = () => {
  const [show_password, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const onHandleLogin = (e) => {
    e.preventDefault();
    if (password == "JeanOnline@#2024") {
      navigate("/");
      localStorage.setItem("auth_user", "JeanTheraphyOnline@#2024");
    } else {
      setError(true);
    }
  };

  return (
    <div className="page-container">
      <form
        className="ps-form--account pt-0 login-admin"
        onSubmit={onHandleLogin}
      >
        <div className="ps-tab active border-radius3 bd-admin-login">
          <div className="ps-form__content p-5 ">
            <div className="text-center mb-4">
              <img src={Logo} alt="No Logo" width="120" />
            </div>
            <h5 className="pb-20 text-center mb-4" style={{fontFamily: "'NewYorkPERSONALUSE', sans-serif", fontSize:"28px"}}>
              Log In Your Account to Continue
            </h5>
            <div className="form-group form-forgot ">
              <div className="position-relative contact-main p-0" style={{borderRadius:"0"}}>
                <input
                  className="form-control"
                  type={show_password ? "text" : "password"}
                  placeholder="Password..."
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError(false);
                  }}
                  style={{height:"50px"}}
                />
                <div className="bd-eye-open">
                  <a onClick={() => setShowPassword(!show_password)}>
                    <i
                      className={
                        show_password ? "icon-eye" : "icon-eye-crossed"
                      }
                    ></i>
                  </a>
                </div>
              </div>
              {error == true ? (
                <span className="text-danger font-12">
                  Please Enter Correct Password
                </span>
              ) : null}
            </div>
            <div className="form-group submit mb-4 mt-4">
              <button
                class="form-control"
                type="submit"
                style={{ backgroundColor: "rgb(0, 182, 188)", color: "white",borderRadius: "25px", padding:"12px" }}
                value="Submit"
              >Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SecureLogon;
