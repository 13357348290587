import React, { useEffect, useRef, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from 'react-router-dom';
import { Config, getDecryptedData } from '../../../config';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Loader from './Loader';

const ImageSlider = ({ bgtext }) => {

  const [isLoading, setLoading] = useState(false);
  const [therapists, setTherapists] = useState([]);

  const customToastOptions = {
    style: {
      border: '1px transparent #ccc',
      padding: '27px 60px',
      backgroundColor: '#c19356',
      color: 'white',
      borderRadius: '10px',
      fontSize: '1.2rem',
      whiteSpace: 'nowrap',
      minWidth: '400px',
      fontWeight: '510',
      maxWidth: '80%',
      zIndex: 9999,
    },
  };

  const createSlug = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-');
  };

  useEffect(() => {
    axios.get(`${Config.apiUrl}/therapistList`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        const data = response.data.data
          .filter(item => item.is_active === 1)
          .filter(item => item.approved === 1)
          .map(item => ({
            name: getDecryptedData(item.name),
            title: getDecryptedData(item.specialist),
            image: item.web_img,
            slug: createSlug(getDecryptedData(item.name))
          }));
        //console.log("Therapist Data", data);
        setTherapists(data);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      });
  }, []);




  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    // swipe: therapists.length > 3 ? true : false,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <div className="slider therapist-slider">

      <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />
      {isLoading && <Loader />}

      {/* <h2>Meet Psychologists</h2> */}
      {/* <h2>Meet Therapists</h2> */}
      <Slider  {...settings}>
        {therapists && therapists.map((psychologist, index) => (
          <div key={index} className="col-md-5 px-4 pad-4">
            <div className={`${bgtext ? 'bg-blue' : 'bg-white'} review-box p-4`}>
              <NavLink to={`${Config.appUrl}therapistdetail/${psychologist.slug}`}>
                <div className="slider-img-container">
                  <img loading='lazy' src={psychologist.image} alt="Therapist Logo" className="slider-img" />
                  <div className="overlay">
                    <span>View Profile</span>
                  </div>
                </div>
              </NavLink>
              <div className="slider-text">
                <h5>{psychologist.name}</h5>
                <h6>{psychologist.title}</h6>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* <ul className="custom-dots">
        {Array.from({ length: groupedDotsCount }, (_, index) => (
          <li
            key={index}
            className={Math.floor(currentSlide / slidesPerGroup) === index ? 'active' : ''}
            onClick={() => handleDotClick(index)}
          >
            {index + 1}
          </li>
        ))}
      </ul> */}
    </div>
  )
}
export default ImageSlider