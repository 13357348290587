import React, { useState, useEffect } from "react";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Footer";
import img1 from "../../assets/img/doctor-1.webp";
import img2 from "../../assets/img/customer-service.webp";
import img3 from "../../assets/img/ambulance.webp";
import img4 from "../../assets/img/shield.webp";
import swal from "sweetalert";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import { schemaMarkup } from "../../config";

const GetStarted = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 300,
    });
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [membership, setMembership] = useState("");
  const [icoNum, setIcoNum] = useState("");
  const [proof, setProof] = useState("");
  const [modality, setModality] = useState("");
  const [experience, setExperience] = useState("");
  const [expertise, setExpertise] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    emergencyContact: "",
    membership: "",
    icoNum: "",
    proof: "",
    modality: "",
    experience: "",
    expertise: "",
  });

  const handleValidation = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "Name is required";
    }

    if (!membership) {
      newErrors.membership = "Memebership Details is required";
    }

    if (!icoNum) {
      newErrors.icoNum = "ICO Number is required";
    }

    if (!emergencyContact) {
      newErrors.emergencyContact = "Emergency Contact is equired";
    }

    if (!proof) {
      newErrors.proof = "Proof of Insurance is required";
    }

    if (!modality) {
      newErrors.modality = "Modality is required";
    }

    if (!experience) {
      newErrors.experience = "Years of Experience is required";
    }

    if (!expertise) {
      newErrors.expertise = "Expertise Description is required";
    }

    if (!phone) {
      newErrors.phone = "Phone Number is required";
    } else if (!/^[+\d]{7,15}$/.test(phone)) {
      newErrors.phone = "Invalid Phone Number";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      swal({
        title: "Success",
        text: "Message Sent Successfully!!",
        icon: "success",
      });
      setName("");
      setPhone("");
      setEmail("");
      setEmergencyContact("");
      setMembership("");
      setModality("");
      setIcoNum("");
      setExperience("");
      setExpertise("");
      setProof("");
    }
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href='https://www.jeantherapyonline.co.uk/getstarted' />
      </Helmet>
      <div className="page-container">
        <Navbar />

        <div>
          <div className="filldet-title1" data-aos="fade-up">
            <h1>Get Started</h1>
          </div>
        </div>

        <div className="get-started-form" data-aos="fade-in">
          <div>
            <div id="application-section">
              <div className="application-form1">
                <div>
                  <div className="filldet-form1">
                    <div className="row">
                      <div className="col-sm-6">
                        <p>
                          <label>Name</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={name}
                              type="text"
                              placeholder="Enter Name"
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            ></input>
                            {errors.name && (
                              <span className="text-danger">{errors.name}</span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          <label>Email</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={email}
                              type="text"
                              placeholder="Enter Email"
                              onChange={(e) => {
                                setEmail(e.target.value.toLowerCase());
                              }}
                            ></input>
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          <label>Phone Number</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={phone}
                              type="text"
                              placeholder="Enter Phone Number"
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                            ></input>
                            {errors.phone && (
                              <span className="text-danger">
                                {errors.phone}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          <label>Emergency Contact</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={emergencyContact}
                              type="text"
                              placeholder="Enter Emergency Contact"
                              onChange={(e) => {
                                setEmergencyContact(e.target.value);
                              }}
                            ></input>
                            {errors.emergencyContact && (
                              <span className="text-danger">
                                {errors.emergencyContact}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          <label>Membership number and regulatory body</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={membership}
                              type="text"
                              placeholder="Enter Membership Number"
                              onChange={(e) => {
                                setMembership(e.target.value);
                              }}
                            ></input>
                            {errors.membership && (
                              <span className="text-danger">
                                {errors.membership}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          <label>ICO number</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={icoNum}
                              type="text"
                              placeholder="Enter ICO Number"
                              onChange={(e) => {
                                setIcoNum(e.target.value);
                              }}
                            ></input>
                            {errors.icoNum && (
                              <span className="text-danger">
                                {errors.icoNum}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          <label>Proof of insurance</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={proof}
                              type="file"
                              placeholder="Enter Proof of Insurance"
                              onChange={(e) => {
                                setProof(e.target.value);
                              }}
                            ></input>
                            {errors.proof && (
                              <span className="text-danger">
                                {errors.proof}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          <label>Modality</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={modality}
                              type="text"
                              placeholder="Enter Modality"
                              onChange={(e) => {
                                setModality(e.target.value);
                              }}
                            ></input>
                            {errors.modality && (
                              <span className="text-danger">
                                {errors.modality}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          <label>Years of experience</label>
                          <br />
                          <span>
                            <input
                              className="form-control"
                              value={experience}
                              type="text"
                              placeholder="Enter Years of Experience"
                              onChange={(e) => {
                                setExperience(e.target.value);
                              }}
                            ></input>
                            {errors.experience && (
                              <span className="text-danger">
                                {errors.experience}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <p>
                          <label>
                            Description experience and expertise (this will be
                            shown to clients)
                          </label>
                          <br />
                          <span>
                            <textarea
                              className="form-control"
                              value={expertise}
                              rows="4"
                              cols="40"
                              placeholder="Enter Description of Experience and Expertise"
                              onChange={(e) => {
                                setExpertise(e.target.value);
                              }}
                            ></textarea>
                            {errors.expertise && (
                              <span className="text-danger">
                                {errors.expertise}
                              </span>
                            )}
                          </span>
                        </p>
                      </div>
                      {/* <div className='padding-seperator'></div> */}
                      <div className="col-sm-12 button-div">
                        <p>
                          <input
                            className="form-control"
                            onClick={handleSubmit}
                            type="submit"
                            value="Submit Application"
                            style={{
                              backgroundColor: "rgb(45, 105, 157)",
                              color: "white",
                              fontSize: "18px",
                            }}
                          ></input>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div className="getstrted">
        <div className="start-div" data-aos="fade-in">
          <img src={img1} alt="Experienced Counsellors"></img>
          <div className="div-title">Experienced Counsellors</div>
          <div className="div-text">
            Your health is your most important asset. You should entrust it only
            to the best professionals.
          </div>
        </div>
        <div className="start-div" data-aos="fade-in">
          <img src={img2} alt="Personalized Counselling"></img>
          <div className="div-title">Personalized Counselling</div>
          <div className="div-text">
            Counselling choices perfectly match your goals of treatment
            complications with early intervention.
          </div>
        </div>
        <div className="start-div" data-aos="fade-in">
          <img src={img4} alt="Quality and Safety"></img>
          <div className="div-title">Quality and Safety</div>
          <div className="div-text">
            All Experts are trained thoroughly to assist in any situation.
          </div>
        </div>
        <div className="start-div" data-aos="fade-in">
          <img src={img3} alt="Immediate Service"></img>
          <div className="div-title">Immediate Service</div>
          <div className="div-text">
            Your counselling plan is designed for steady progress, with every
            phase promptly implemented.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default GetStarted;
