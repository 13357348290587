import React, { useEffect, useState } from "react";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Footer";
import { Config, schemaMarkup } from "../../config";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Common/Loader";
import notfound from "../../assets/img/notfound_img.jpg";
import TransformCTA from "../components/Common/TransformCTA";
import { Helmet } from "react-helmet";

const Blog = () => {
  const pageName = "blog";

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 300,
    });
  });

  const createSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-+|-+$/g, "");
  };

  const stripHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const truncate = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${Config.apiUrl}/blogs`, {
        headers: {
          //'Authorization': 'Bearer ',
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        //console.log("Data Blog",response.data.data);
        const data = response.data.data.map((item) => ({
          title: item.title,
          description: truncate(stripHtml(item.content), 200),
          image: item.image_url,
          date: formatDate(item.created_at),
          link: createSlug(item.title),
          animation: "fade-right",
        }));

        setBlogData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="overflow">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://www.jeantherapyonline.co.uk/blog" />
      </Helmet>
      {loading && <Loader />}
      <div className="page-container">
        <Navbar pageName={pageName} />

        <div className="blog-page p-5" data-aos="fade-in">
          <div>Our Blogs</div>
        </div>

        <div style={{ overflow: "hidden" }}>
          <div>
            <div className="bolgs-section">
              <div className="bg-white">
                <div className="blog">
                  <div className="blog-title" data-aos="fade-in">
                    <h4>
                      {" "}
                      <i className="bi bi-clock-fill px-3"></i>
                      Recent Blogs
                    </h4>
                  </div>
                  <section>
                    <div className="recent-blog">
                      {blogData &&
                        blogData.map((post, index) => (
                          <div
                            key={index}
                            className="recent-col"
                            data-aos={post.animation}
                          >
                            <NavLink
                              to={`${Config.appUrl}blogdetail/${post.link}`}
                            >
                              <div className="blog-img">
                                <img src={post.image} alt={notfound} />
                              </div>
                            </NavLink>
                            <div className="blog-content">
                              <span>{post.date}</span>
                              <h5>
                                <NavLink
                                  to={`${Config.appUrl}blogdetail/${post.link}`}
                                >
                                  {post.title}
                                </NavLink>
                              </h5>
                              <p>{post.description}</p>
                              <div>
                                <Link
                                  to={`${Config.appUrl}blogdetail/${post.link}`}
                                >
                                  Read More
                                  <i className="bi bi-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Cta Section */}
      <div className="other-content">
        <TransformCTA />
      </div>
      <Footer />
    </div>
  );
};
export default Blog;
