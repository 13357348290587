import React, { useState, useEffect } from "react";
import { Config, schemaMarkup } from "../../config";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Footer";
import ctaimg from "../../assets/img/positive-young-hindu-lady-freelancer-working-on-la-2023-11-27-04-55-54-utc-scaled.webp";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import TransformCTA from "../components/Common/TransformCTA";
import { Helmet } from "react-helmet";

const FAQ = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 300,
    });
  });

  const pageName = "faq";

  const [accordianAll, setAccordianAll] = useState(false);

  const data = [
    {
      title: "How do I know if therapy is right for me?",
      desc: "Therapy can be beneficial for anyone facing challenges or seeking support in managing their emotions, relationships, or life transitions. If you're feeling overwhelmed, stressed, anxious, depressed, or simply seeking guidance in navigating life's complexities, therapy could be a helpful resource for you.",
    },
    {
      title: "What can I expect during a therapy session?",
      desc: "The sessions typically involve open and confidential discussions between you and the counsellor. Your counsellor will listen attentively, provide empathy, and support, and offer guidance in exploring your thoughts, feelings, and behaviours. Together, you'll work to identify goals and develop strategies to address your concerns.",
    },
    {
      title:
        "How long does the therapy session last, and how frequently should I attend sessions?",
      desc: "The duration of the session is 50 minutes. Some people may find relief after just a few sessions, while others may benefit from longer-term support. The frequency of sessions is usually determined collaboratively between you and your counsellor, with sessions typically weekly. ",
    },
    {
      title: "Is therapy confidential?",
      desc: "Yes, therapy is typically confidential. Counsellors are bound by professional ethics and legal standards to protect your privacy and keep your discussions confidential. However, there are some exceptions to confidentiality, such as instances where there is a risk of harm to yourself or others, or when required by law.",
    },
    {
      title: "How do I know if I've found the right counsellor for me?",
      desc: "Finding the right counsellor is essential for a positive therapy experience. It's important to consider factors such as the counsellor’s approach, communication style, personality, and expertise in addressing your specific concerns. Trust your instincts and take note of how comfortable and understood you feel during your initial sessions.",
    },
    {
      title:
        "What if I'm not comfortable talking about certain topics during therapy?",
      desc: "It's entirely normal to feel hesitant or uncomfortable discussing certain topics during therapy. Your counsellor will respect your boundaries and pace the sessions according to your comfort level. You're encouraged to communicate openly with your counsellor about any concerns or reservations you may have, and they can help you navigate challenging topics at a pace that feels manageable for you.",
    },
    {
      title: "How can I make the most out of my therapy experience?",
      desc: "To maximize the benefits of therapy, it's essential to approach it with openness, honesty, and commitment. Be willing to engage actively in the process, set realistic goals, and communicate openly with your counsellor about your progress, challenges, and preferences. Additionally, practice any coping strategies or homework assignments suggested by your counsellor outside of sessions to reinforce your progress and self-awareness.",
    },
  ];

  return (
    <div className="overflow">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href='https://www.jeantherapyonline.co.uk/faq' />
      </Helmet>
      <div className="page-container">
        <Navbar pageName={pageName} />

        <div className="faq-container">
          <div className="faq-title" data-aos="fade-in">
            <h1>FAQs</h1>
          </div>
        </div>
      </div>

      <div className="faq-content">
        <div data-aos="fade-up">
          <div className="mt-4 pb-5">
            <div
              className="accordion accordion-flush accordian-transparent"
              id={`accordionFlushExample`}
            >
              {data.slice(0, accordianAll ? 18 : 10).map((item, i) => {
                return (
                  <div key={i} className="accordion-item bg-transparent">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapseThree${i}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapseThree${i}`}
                      >
                        <div className="index rounded-circle bg-golden text-black me-3">
                          {i + 1}
                        </div>
                        <div> {item.title}</div>
                      </button>
                    </h2>
                    <div
                      id={`flush-collapseThree${i}`}
                      className={`accordion-collapse collapse ${
                        i == 0 && "show"
                      }`}
                      data-bs-parent={`#accordionFlushExample`}
                    >
                      <div className="accordion-body">
                        <p className="paragraph">{item.desc}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="mt-5 text-end">
                            <button
                                className="button bg-btn2 mb-5"
                                onClick={() => setAccordianAll(!accordianAll)}
                            >
                                Show {accordianAll ? 'Less' : 'More'}
                                <i className={`bi bi-arrow-${accordianAll ? 'up' : 'down'} ms-2`}></i>
                            </button>
                        </div> */}
        </div>
      </div>

      <div className="cta-div">
        <TransformCTA />
      </div>

      <Footer />
    </div>
  );
};
export default FAQ;
