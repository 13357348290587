import React, { useState } from "react";
import swal from "sweetalert";
import { Config } from "../../../config";
import { NavLink } from "react-router-dom";

function CTAPopup() {

    return (
        <>
            <section>
                <div className='contact-ctabg-img'>
                    <div className='text align-items-center'>
                        {/* <h2>GET IN TOUCH</h2> */}
                        <div className="text-div">
                            <h3 className="mb-0">
                                If you find yourself in crisis, please call 999 or you can reach out to the Samaritans on
                                <span style={{ color: '#6faac1' }}> 116 123.</span> </h3>
                            {/* <h3>Take control of your well-being</h3> */}
                            {/* <h4>Contact us today for compassionate therapy.</h4>
                            <h4>In case of crisis, contact 999.</h4> */}
                        </div>
                        {/* 07512788885 */}
                        {/* <div className='button-div'>
                            <div className='cta-button1 ms-auto'>
                                <a href="tel:999" style={{ textDecoration: 'none', color: 'white' }}>
                                    Emergency Contact : 999
                                    <a href="tel:999" onClick={(e) => e.stopPropagation()} className="text-white text-decoration-none " style={{paddingLeft: '0px'}}> </a>
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
export default CTAPopup