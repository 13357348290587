import React, { useEffect, useState, useRef, Suspense } from "react";
import Navbar from "../../components/Common/Navbar";
import "../../../styles/common/Navbar.css";
import img from "../../../assets/img/man-with-psychologist-in-the-office-62XB78R11.jpg";
import ladypic from "../../../assets/img/image_03_home_02.png";
import Footer from "../../components/Footer";
import PlanSection from "../../components/Common/PlanSection";
import AOS from "aos";
import "aos/dist/aos.css";
import svg1 from "../../../assets/img/service_5.svg";
import svg2 from "../../../assets/img/Group.svg";
import ImageSlider from "../../components/Common/ImageSlider";
import ContactSection from "../../components/Common/ContactSection";
import swal from "sweetalert";
import CTAPopup from "../../components/Common/CTAPopup";
import PayAsYouGo from "../../components/Common/PayAsYouGo";
import SvgDivider from "../../components/Common/SvgDivider";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Config, schemaMarkup } from "../../../config";
import axios from "axios";
import Loader from "../../components/Common/Loader";
import notfound from "../../../assets/img/notfound_img.jpg";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";

function Home() {
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);


  const targetSectionRef = useRef(null);

  const scrollToSection = () => {
    if (targetSectionRef && targetSectionRef.current) {
      window.scrollTo({
        top: targetSectionRef.current.offsetTop + 200,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 300,
    });
  });

  const pageName = "homepage";

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const setViewMore = () => {
    setShowMore(!showMore);
    // window.scrollTo(0, 3600)
  };

  const createSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-+|-+$/g, "");
  };

  const stripHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const truncate = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const dateDifferenceInDays = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const currentDate = new Date();

  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${Config.apiUrl}/blogs`, {
        headers: {
          //'Authorization': 'Bearer ',
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        //console.log("Data Blog", response.data.data);
        const data = response.data.data.map((item) => ({
          title: item.title,
          description: truncate(stripHtml(item.content), 200),
          image: item.image_url,
          date: formatDate(item.created_at),
          link: createSlug(item.title),
          animation: "fade-right",
        }));

        const sortedData = data.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return (
            dateDifferenceInDays(dateA, currentDate) -
            dateDifferenceInDays(dateB, currentDate)
          );
        });

        const nearestThreeBlogs = sortedData.slice(0, 3);

        setBlogData(nearestThreeBlogs);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="overflow">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://www.jeantherapyonline.co.uk" />
      </Helmet>

      {loading && <Loader />}

      <div className="main-container">
        <Navbar pageName={pageName} />
        {/* <HomeBanner /> */}

        {/* Promo Section */}
        <div className="promo-section">
          <div className="img-div" data-aos="fade-right">
            <div className="inner-img-div">
              <img loading="lazy" src={img} alt="Jenatherapyonline Logo"></img>
            </div>
          </div>
          <div className="text-div" data-aos="fade-up">
            <h1 className="main-banner-head text-center col-md-9">
              Professional Mental Health <br /> Consultation Online
            </h1>{" "}
            <div>
              <span className="headline text-center">
                Reclaim Your Well-Being: Enjoy 4 Personalized Therapy Sessions for Just £199!
              </span>
            </div>
            {/* <div> <span className="headline">Private mental health consultation from a professional</span></div> */}
            <div>
              <span className="bt_bb_headline_content1">
                {/* <span>Begin Feeling Better Today.</span> */}
                <span>FIND YOURSELF IN THERAPY</span>
              </span>
            </div>
            <div className="plan-button1">
              <div className="book-button1">
                <div
                // onClick={scrollToSection}
                >
                  <Link to={`${Config.appUrl}get-counselling`} className="text-decoration-none">
                    <span className="text-white ">Book Now</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* What We Offer */}
      <div className="sf-section">
        <div className="second-sec">
          <div className="step-forward" data-aos="fade-up">
            <div>
              <h4>WHAT WE OFFER</h4>
            </div>
          </div>
          <div className="step-forward1" data-aos="fade-up">
            <div>
              <h2>Embrace Wellness: Let Therapy Be Your Guide</h2>
            </div>
          </div>
          <div className="section-content" data-aos="fade-in">
            <div className="row-sf">
              <div className="sf-col">
                <div className="sf-content">
                  <span className="bt_bb_icon_holder">
                    <span className="bt_bb_icon_holder_inner">
                      <img className="upwards-hover" src={svg1} alt="Jeantherapyonline Logo"></img>
                    </span>
                  </span>
                </div>
                <div>
                  <div className="sf-text1">
                    <div>Individual Therapy</div>
                  </div>
                  <div className="sf-text2">
                    {" "}
                    <div>
                      Individual therapy involves a confidential and supportive
                      relationship between a therapist and a client. It
                      typically focuses on addressing personal issues, emotional
                      challenges, and mental health concerns through
                      conversation, exploration, and tailored interventions.
                    </div>
                  </div>
                  {/* <div className='sf-text3'><div>More Info <i className='bi bi-arrow-right'></i></div></div> */}
                </div>
              </div>
              <div className="sf-col">
                <div className="sf-content">
                  <span className="bt_bb_icon_holder">
                    <span className="bt_bb_icon_holder_inner">
                      <img className="upwards-hover" src={svg2} alt="Jeantherapyonline Logo"></img>
                    </span>
                  </span>
                </div>
                <div>
                  <div className="sf-text1">
                    <div>Couples Therapy </div>
                  </div>
                  <div className="sf-text2">
                    {" "}
                    <div>
                      Couples therapy involves a collaborative process between a
                      qualified therapist and a couple to address relationship
                      issues and improve communication, understanding, and
                      intimacy.
                    </div>
                  </div>
                  {/* <div className='sf-text3'><div>More Info <i className='bi bi-arrow-right'></i></div></div> */}
                </div>
              </div>
              {/* <div className="sf-col">
                <div className="sf-content">
                  <span className="bt_bb_icon_holder">
                    <span className="bt_bb_icon_holder_inner">
                      <img className="upwards-hover" src={svg3}></img></span></span>
                </div>
                <div>
                  <div className='sf-text1'><div>Relationships</div></div>
                  <div className='sf-text2'> <div>Take our quiz to find the therapist who’s right for you and book a free 15-minute phone consultation.</div>
                  </div>
                  <div className='sf-text3'><div>More Info <i className='bi bi-arrow-right'></i></div></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <CTAPopup />

      {/* Plans Section */}
      <div ref={targetSectionRef}>
        <PlanSection />
      </div>

      {/* <PayAsYouGo /> */}

      {/* Build YourSelf Section */}
      <SvgDivider />
      <div className="building-section1">
        <div className="building-section">
          <div className="img-section" data-aos="fade-right">
            <img src={ladypic} alt="Jeantherapyonline Logo"></img>
          </div>
          <div className="text-section" data-aos="fade-in">
            <div className="text1">
              IT'S TIME TO TAKE A STEP TOWARD THE LIFE YOU WANT
            </div>
            <div className="text2">Building A Relationship With Yourself</div>
            <div className="text3">
              <p>
                Building a relationship with yourself is a dynamic, ongoing
                process that involves self-awareness, self-acceptance, and
                self-care. Here are several key aspects to consider:
              </p>
              <div>
                <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
                <b>Reflection:</b> Regularly take time to reflect on your
                thoughts, emotions, and behaviours. Journaling can be a useful
                tool for this.
              </div>
              <div>
                <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
                <b>Mindfulness:</b> Practice mindfulness or meditation to stay
                present and understand your inner experiences better.{" "}
              </div>
              <div>
                <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
                <b>Honesty:</b> Be honest with yourself about your strengths,
                weaknesses, desires, and fears.
              </div>
            </div>
          </div>
        </div>
        {showMore && (
          <div className="text-section1">
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Compassion:</b> Treat yourself with the same kindness and
              compassion that you would offer to a friend. Acknowledge your
              imperfections without harsh judgment.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Embrace Uniqueness:</b> Accept and appreciate your unique
              qualities and individuality. Avoid comparing yourself to others.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Physical Health:</b> Maintain a healthy lifestyle by eating
              nutritious foods, exercising regularly, getting adequate sleep,
              and seeking medical care when needed.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Boundaries:</b> Set healthy boundaries in your personal and
              professional life to protect your time and energy.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Learning:</b> Commit to continuous learning and personal
              growth. Read books, take courses, or pursue new skills that
              interest you.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Goals:</b> Set personal goals and work towards achieving them
              but be flexible and forgiving with yourself if things don’t go as
              planned.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Voice:</b> Find healthy ways to express your thoughts and
              emotions, whether through writing, art, music, or conversation.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Authenticity:</b> Be true to yourself in your interactions with
              others. Express your true thoughts and feelings rather than
              conforming to others' expectations.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Decisions:</b> Trust your intuition and make decisions that
              align with your values and beliefs.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Confidence:</b> Build confidence by acknowledging your
              achievements and learning from your experiences.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Gratitude:</b> Practice gratitude by regularly acknowledging
              and appreciating the positive aspects of your life.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Positive Self-Talk:</b> Cultivate a positive inner dialogue.
              Challenge negative thoughts and replace them with affirming
              statements.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Social Relationships:</b> Foster relationships with others who
              support and uplift you. Seek out positive, encouraging social
              interactions.
            </div>
            <div>
              <i className="bi bi-check2" style={{ color: "#2596be" }}></i>{" "}
              <b>Therapy or Counselling:</b> Consider seeking professional help
              if you need support in understanding yourself or dealing with
              personal issues.
            </div>
            <div className="mt-3">
              Building a relationship with yourself is about nurturing and
              valuing who you are. It requires ongoing effort, patience, and
              dedication, but it leads to a more fulfilling and balanced life.
            </div>
          </div>
        )}
        <div
          style={{ display: "flex", justifyContent: "end", paddingRight: "3%" }}
        >
          <div
            style={{
              color: "rgb(19, 71, 87)",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={setViewMore}
          >
            {showMore ? (
              <div>View Less...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            ) : (
              <div>View More...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="page-container1">
          <div className="img-slider text-center">
            <h2>Meet Therapists</h2>

            <ImageSlider bgtext="bg-blue" />
          </div>

          {/* Contact Section */}
          <ContactSection />
        </div>
      </div>

      <div className="bg-blue">
        {/* We Can Help */}
        <div className="bg-blue">
          <div className="we-can-help section-content1">
            <div className="div2" data-aos="fade-up">
              <h1>Latest Articles</h1>
            </div>

            <div className="">
              <section>
                <div className="section-container1">
                  {blogData &&
                    blogData.map((post, index) => (
                      <div
                        key={index}
                        className="section-col2"
                        data-aos={post.animation}
                      >
                        <NavLink to={`${Config.appUrl}blogdetail/${post.link}`}>
                          <div className="element-wrap1">
                            <div
                              style={{ marginBottom: "15px" }}
                              data-aos="fade-right"
                            >
                              <div>
                                <img src={post.image} alt={notfound} />
                              </div>
                            </div>
                          </div>
                        </NavLink>
                        <div data-aos="fade-up">
                          <div className="element-content1">
                            <span>{post.date}</span>
                            <h5>
                              <NavLink
                                to={`${Config.appUrl}blogdetail/${post.link}`}
                              >
                                {post.title}
                              </NavLink>
                            </h5>
                          </div>
                          <div className="elementor-element elementor-element-dd81ada elementor-widget1 elementor-widget-heading">
                            <div className="elementor-widget-container1">
                              <div className="elementor-heading-title elementor-size-medium">
                                {post.description}
                              </div>
                            </div>
                          </div>
                          <div className="readmore-div">
                            <NavLink
                              to={`${Config.appUrl}blogdetail/${post.link}`}
                            >
                              <div className="view-more">
                                Read More <i className="bi bi-arrow-right"></i>
                              </div>
                            </NavLink>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    ))}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  );
}

export default Home;
